<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <el-select v-model="searchForm.type" placeholder="请选择类型" class="mgB10 custom-form-input"
        @change="typeChange">
        <el-option label="全部" value=""></el-option>
        <el-option v-for="(item,index) in typeList" :key="index" :label="item.cangName" :value="item.cangType">
        </el-option>
      </el-select>
      <el-select v-model="searchForm.houseId" placeholder="请选择仓房" class="mgL10 mgB10 custom-form-input">
        <el-option label="全部" value=""></el-option>
        <el-option v-for="(item,index) in cangList" :key="index" :label="item.houseNo" :value="item.locaId">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button>
      <el-button type="success" @click="add" plain class="mgL10 mgB10">添加</el-button>
      <el-table :data="tableData" border stripe v-loading="loading" style="width: 100%;">
        <el-table-column prop="houseName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓房名称"></el-table-column>
        <el-table-column prop="mappingSpot" :show-overflow-tooltip="true" header-align="center" align="center"
          label="显示点数">
        </el-table-column>
        <el-table-column prop="dataSpot" :show-overflow-tooltip="true" header-align="center" align="center"
          label="数据点数"></el-table-column>
        <el-table-column label="操作" width="100" header-align="center" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach"></table-page>
    </el-card>
    <!-- 编辑  添加-->
    <el-dialog class="custom-dialog" :title="formType?'编辑':'添加'" :visible.sync="dialogVisible" width="360px"
      :append-to-body="true" @close="closeAlert">
      <el-form ref="form" :rules="formRules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="仓型" prop="cangType" class="custom-form-item">
              <el-select v-model="form.cangType" placeholder="请选择仓型" style="width: 100%;" @change="typeFormChange">
                <el-option v-for="(item,index) in typeList" :key="index" :label="item.cangName" :value="item.cangType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="仓房名称" prop="houseNo" class="custom-form-item">
              <el-select v-model="form.houseNo" style="width: 100%;" placeholder="请选择仓房">
                <el-option v-for="(item,index) in cangFormList" :key="index" :label="item.houseNo" :value="item.locaId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="数据点数" prop="dataSpot" class="custom-form-item">
              <el-input-number v-model="form.dataSpot" style="width: 100%;" controls-position="right" :min="0">
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="显示点数" prop="mappingSpot" class="custom-form-item">
              <el-input-number v-model="form.mappingSpot" style="width: 100%;" controls-position="right" :min="0">
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
        </el-button>
        <el-button @click="closeAlert">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    components: {
      "table-page": () => import('@/components/TablePage')
    },
    data() {
      return {
        searchForm: {
          houseId: '',
          page: 1,
          limit: 10,
          type: ""
        },
        total: 0,
        typeList: [],
        cangList: [],
        tableData: [],
        loading: false,
        dialogVisible: false,
        formType: false,
        form: {
          id: "",
          houseNo: "",
          dataSpot: "",
          mappingSpot: "",
          cangType: ""
        },
        cangFormList: [],
        loadingAlert: false,
        formRules: {
          houseNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          dataSpot: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          mappingSpot: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        }
      }
    },
    mounted() {
      this.cfCangTypeList()
      this.getAbarnNames()
      this.search()
    },
    methods: {
      typeFormChange(e) {
        this.cangFormList = [];
        this.getAbarnNames(e).then(res => {
          this.cangFormList = res;
        })
      },
      //仓房类型修改
      typeChange(e) {
        this.searchForm.houseId = ""
        this.cangList = [];
        if (e || e === 0) {
          this.getAbarnNames(e).then(res => {
            console.log(res)
            this.cangList = res;
          })
        }
      },
      //获取仓房信息
      async getAbarnNames(type) {
        let res = await this.$axios.Get(this.$api.byCangtypeLiangLocaidNo + '?' + encodeURIComponent("cangType[]") +
          "=" + [type].toString(), {})
        return res.data
      },
      //获取仓房类型
      cfCangTypeList() {
        this.$axios.Get(this.$api.cfCangTypeList, {}).then(res => {
          this.typeList = res.data;
        })
      },
      //添加
      add() {
        this.dialogVisible = true;
      },
      editRow(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.formType = true;
        this.dialogVisible = true;
      },
      //提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form)
            // this.formType ? this.editUser() : this.addUser()
            this.addUser()
          } else {
            return false;
          }
        });
      },
      //添加信
      addUser() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfMmappingSpotAdd, this.form).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
          this.closeAlert();
          this.search()
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
      //关闭
      closeAlert() {
        this.formType = false;
        this.dialogVisible = false;
        this.$refs["form"].resetFields();
        this.form.id = "";
        this.loadingAlert = false;
        // this.getNotArchivesHouse()
      },
      //搜索
      search() {
        this.getData(1, this.searchForm.limit)
      },
      //分页
      feach(e) {
        this.getData(e.page, e.limit)
      },
      //删除
      delRow(e) {
        this.$confirm('此操作将永久删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let than = this;
          this.$axios.Post(this.$api.cfMmappingSpotDel, {
            "id": e.id
          }).then(() => {
            than.$message({
              message: '删除成功',
              type: 'success'
            });
            than.getData(than.searchForm.page, than.searchForm.limit)
          })
        }).catch(() => {});
      },
      //获取数据
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.cfMmappingSpotSee, {
          houseId: this.searchForm.houseId,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      }
    },
  }
</script>

<style scoped lang="less">
</style>
